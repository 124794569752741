@font-face {
    font-family: 'Helvetica';
    font-weight: 400;
    src: url('../fonts/Helvetica-Regular.woff') format('woff'),
         url('../fonts/Helvetica-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Helvetica';
    font-weight: 700;
    src: url('../fonts/Helvetica-Bold.woff') format('woff'),
         url('../fonts/Helvetica-Bold.woff2') format('woff2');
}

@keyframes fadein {
    from { opacity: 0 }
    to { opacity: 1 }
}

.main-page {
    height: 100vh;
}

.main-page .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
}

.main-title {
    font-family: 'Helvetica', sans-serif;
    font-size: 27px;
    font-weight: 700;
    line-height: 1.48;
    text-align: center;
    text-transform: uppercase;
    color: #05b0ff;
    width: 100%;
    flex-shrink: 0;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 14px;
}

.ru .main-title {
    font-size: 22px;
}

.wr-slider {
    // flex-grow: 1;
    margin-bottom: 30px;
}

.wr-slider img {
    width: 100%;
}

.wr-slider .slick-list,
.wr-slider .slick-slider {
    position: relative;
}

.wr-slider .slick-dots button,
.wr-slider .slick-dots li {
    width: 5px;
    height: 5px;
}

.wr-slider .slick-dots button {
    opacity: 0.45;
    background-color: #999999;
}

.wr-slider .slick-dots .slick-active button {
    opacity: 1;
    background-color: #05b0ff;
}

.wr-slider .slick-dots {
    bottom: -5px;
}

.slide {
    display: flex;
    flex-wrap: wrap;
}

.slide-item {
    margin: 0 7px 14px;
}

.slide-item h2 {
    font-size: 16px;
    font-weight: bold;
    font-style: oblique;
    line-height: 1;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
}

.slide-image-container {
    max-width: 140px;
    border-radius: 16px;
    overflow: hidden;
}

.slide-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slide-item-active {
    position: relative;
}

.slide-item-active .slide-image-container {
    box-shadow: 0 0 120px 0 #7ecd21;
    border: 2px solid #7ecd21;
    box-sizing: border-box;
    max-width: 200px;
}

.btn-upload-foto {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    max-width: 310px;
    width: 90%;
    height: 48px;
    border-radius: 100px;
    background-color: #7ecd21;
    display: block;
    flex-shrink: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    margin: 0 auto;
}

.create-page h2 {
    font-weight: bold;
    color: #333;
    line-height: 1.38;
    text-align: center;
    margin-bottom: 20px;
}

.create-page .create-steps {
    line-height: 1.38;
    text-align: center;
    color: #999;
    margin-bottom: 8px;
}

.creatives {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 30px;
}

.creative {
    width: 48%;
    position: relative;
    margin: 0 0 15px;
}

.creative .num-check-foto {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #05b0ff;
    display: none;
    align-items: center;
    justify-content: center;
}

.creative .holder {
    position: relative;
    margin-bottom: 8px;
    padding-top: 100%;
    background-color: #eeeeee;
}

.creative.active .num-check-foto {
    display: flex;
}

.creative img {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
}

.creative.active img {
    // box-shadow: 0 0 40px 0 #c77dff;
    border: solid 3px #05b0ff;
    box-sizing: border-box;
}

.creative p {
    font-size: 16px;
    line-height: 1;
    text-align: center;
    color: #333;
    text-transform: capitalize;
}

.create-page .btn-upload-foto {
    position: fixed;
    bottom: 15px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.create-page .btn-upload-foto.disabled {
    background-color: #3b3c3e;
}

.collage-page img {
    width: 100%;
}

.collage-page .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100vh;
}

/*loader*/
@keyframes spinner-1--before {
    0% {
        // transform: scale(1);
        opacity: .8;
    }

    50% {
        // transform: scale(3, 3);
        width: 300%;
        height: 300%;
        opacity: 0;
    }
    100% {
        width: 90%;
        height: 90%;
        opacity: 0;
    }
}

.spinonediv-1 {
    width: 140px;
    height: 140px;
    box-shadow: 0 0 40px 0 rgba(5, 176, 255, 0.6);
    // border: solid 2px #c77dff;
    border-radius: 50%;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    position: relative;
}

.loader p {
    font-size: 18px;
    line-height: 1.33;
    text-align: center;
    color: #333;
    position: absolute;
    top: calc(45% + 220px);
    left: 50%;
    transform: translate(-50%,-50%);
    width: 75%;
}

.spinner-wave,
.spinner-wave2,
.spinner-wave3,
.spinner-wave4,
.loader-image-container {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.spinonediv-1:before,
.spinonediv-1:after,
.spinner-wave:before,
.spinner-wave:after,
.spinner-wave2:before,
.spinner-wave2:after,
.spinner-wave3:before,
.spinner-wave3:after,
.spinner-wave4:before,
.spinner-wave4:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    border: 1px solid #05b0ff;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    box-sizing: border-box;
    animation: spinner-1--before 12s linear infinite;
    // transform: scale(1);
    transform: translate(-50%,-50%);
    opacity: 0;
}

.spinonediv-1:after {
    animation-delay: 1.2s;
}

.spinner-wave:before {
    animation-delay: 2.4s;
}

.spinner-wave:after {
    animation-delay: 3.6s;
}

.spinner-wave2:after {
    animation-delay: 4.8s;
}

.spinner-wave2:before {
    animation-delay: 6s;
}

.spinner-wave3:after {
    animation-delay: 7.2s;
}

.spinner-wave3:before {
    animation-delay: 8.4s;
}

.spinner-wave4:after {
    animation-delay: 9.6s;
}

.spinner-wave4:before {
    animation-delay: 10.8s;
}


.spinner-wave2 {
    position: relative;
}

.loader-image-container {
    overflow: hidden;
    position: relative;
    z-index: 10;
}

.spinner-wave2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar-image {
    position: absolute;
    top: 0;
    left: 0;
    border: solid 2px #05b0ff;
    box-sizing: border-box;
    border-radius: 50%;

    animation-name: fadein;
    animation-duration: 320ms;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

/*modal*/
.-show-popup {
    overflow: hidden;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.modal {
    max-width: 314px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    background-color: #fff;
    padding: 20px 16px 16px;
}

.modal img {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-top: -40px;
}

.modal p {
    font-size: 14px;
    text-align: center;
    color: #030303;
    margin-top: -28px;
    margin-bottom: 16px;
}

.modal .btn-upload-foto {
    max-width: 148px;
}

/*error-page*/
.error-page .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    line-height: 1.38;
}

.error-page svg {
    max-width: 264px;
    margin-bottom: 5.5vh;
}

.error-page h3 {
    font-weight: bold;
    margin-bottom: 16px;
}

.btn-back {
    font-size: 15px;
    font-weight: bold;
    color: #7ecd21;
    display: flex;
    align-items: center;
    background-color: transparent;
    position: relative;
    top: -5px;
    left: -5px;
    padding: 5px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    margin-bottom: 9px;
}

.btn-back svg {
    width: 11px;
    margin-right: 10px;
}

.collage-page h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.38;
    text-align: center;
    color: #333;
    width: 100%;
    margin-bottom: 16px;
    text-transform: uppercase;
}

.share-container {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
}

.share-container button {
    width: 40px;
    height: 40px;
    border: none;
    background: no-repeat;
    flex-shrink: 0;
    margin-left: 24px;
    &:last-child {
        padding: 0 1px;
        box-sizing: border-box;
        svg {
            margin-right: 23px;
            margin-left: -1px;
        }
    }
}

.share-container svg {
    width: 100%;
}

.steps-progress {
    position: fixed;
    height: 4px;
    width: 100%;
    background-color: #e7e7e7;
    top: 0;
    left: 0;
    z-index: 50;
    span {
        transition: 500ms;
        background-color: #feca28;
        display: block;
        height: 100%;
    }
}

@media all and (max-height: 720px) and (orientation: landscape) {
    .wr-slider img {
        width: 90%;
        margin: 0 auto;
        display: block;
    }
}

@media all and (max-width: 430px) and (max-height: 630px) {
    .wr-slider img {
        width: 100%;
        max-height: 350px;
        object-fit: contain;
    }
}

@media all and (max-height: 520px) {
    .main-title {
        font-size: 20px;
    }
    .wr-slider {
        margin-top: -20px;
    }
    .wr-slider img {
        max-height: 310px;
        object-fit: contain;
    }
}