// html, body, .root, main {
//     height: 100%;
// }
// .root > div {
//     height: 100%;
//     & > div {
//         height: 100%;
//     }
// }

[hidden] {
    display: none !important;
}

.file-field-hidden {
    display: none;
}

body {
    font-family: 'Helvetica', sans-serif;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    overflow-x: hidden;
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

.container {
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
    padding: 14px 30px 27px;
    margin: 0 auto;
}

p {
    line-height: normal;
}

button {
    cursor: pointer;
    position: relative;
    padding: 0;
    input {
        opacity: 0;
        height: 0 !important;
        position: absolute;
        padding: 0 !important;
        margin: 0 !important;
    }
    &[disabled] {
        color: #999;
        background-color: #f3f3f3;
    }
}

a {
    text-decoration: none;
}

input {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

button {
    border: none;
}

/*loader*/
.-show-loader {
    .block-loader {
        display: flex;
    }
}
.-show-loader {
    .popup-foto {
        display: none;
    }
}
.block-loader {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 100;
}

.loader-text {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 120px);
    width: 100%;
    padding: 0 20px;
}

.triple-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
        object-fit: cover;
        width: 214px;
        height: 214px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        margin-top: -9vh;
    }
}

.triple-spinner {
    position: relative;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top: 5px solid #fc4700;
    border-right: 5px solid #fc4700;
    animation: spin 2s linear infinite;
    box-sizing: border-box;
    &::before,
    &::after {
        content: "";
        position: absolute;
        border-radius: 50%;
        border: 5px solid transparent;
    }

    &::before {
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        border-top-color: #ffd300;
        border-right-color: #ffd300;
        animation: spin 3.5s linear infinite;
    }
    &::after {
        top: 12px;
        left: 12px;
        right: 12px;
        bottom: 12px;
        border-top-color: #1db8ff;
        border-right-color: #1db8ff;
        animation: spin 1.75s linear infinite;
    }
}

.large-spinner {
    width: 288px;
    height: 288px;
    margin-top: -18vh;
}
.small-spinner {
    width: 100px;
    height: 100px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes spin-reverse {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

/*slider*/
.slick-list,
.slick-slider {
    position: initial;
    display: block;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots {
    li {
        position: relative;
        display: inline-block;
        width: 6px;
        height: 6px;
        cursor: pointer;
        border-radius: 50%;
        padding: 0;
        margin: 0 3px;
    }
    button {
        width: 6px;
        height: 6px;
        cursor: pointer;
        border-radius: 50%;
        padding: 0;
        margin: 0 3px;
        color: transparent;
        border: 0;
        outline: none;
        background: #e0e0e0;
    }
    .slick-active {
        button {
            background: #000;
        }
    }
}

.slick-slide {
    float: left;
    font-size: 0;
}
