.imageview-host {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border-radius: 8px;
  background-color: rgba(0, 0, 0, .1);

  .imageview-holder {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .imageview-holder,
  .imageview-image {
    animation: imageview-show 320ms;
  }
}

@keyframes imageview-show {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
